/** @namespace Scandipwa/Component/Collapsible/Component */
import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import { next } from 'Util/Element';

import './Collapsible.style.scss';

/** @namespace Scandipwa/Component/Collapsible/Component */
export class CollapsibleComponent extends PureComponent {
    state = {
        isOpen: false
    };

    handleElementClick(event) {
        const element = next(event?.target);
        if (element) {
            if (element?.style?.display === 'block') {
                element.style.display = 'none';
                this.setState({
                    isOpen: false
                });
            } else {
                element.style.display = 'block';
                this.setState({
                    isOpen: true
                });
            }
        }
    }

    renderIcon() {
        const { isOpen } = this.state;

        if (isOpen) {
            return <Icons block="Collapsible" elem="Icon" name="arrow_up" />;
        }

        return <Icons block="Collapsible" elem="Icon" name="arrow_down" />;
    }

    render() {
        const {
            children, elementName: ElementName,
            elementProps
        } = this.props;

        return (
            <ElementName
              { ...elementProps }
              onClick={ this.handleElementClick.bind(this) }
            >
                { children }
                { this.renderIcon() }
            </ElementName>
        );
    }
}
