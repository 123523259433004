import ProductListPage from 'Component/ProductListPage';
import {
    ProductList as SourceProductList
} from 'SourceComponent/ProductList/ProductList.component';

/** @namespace Scandipwa/Component/ProductList/Component */
export class ProductListComponent extends SourceProductList {
    renderPage(props = {}) {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            currentPage,
            mix, isWidget
        } = this.props;

        const newProps = this._processProps(props);

        return (
            <ProductListPage
              key={ currentPage }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              isWidget={ isWidget }
              { ...newProps }
            />
        );
    }

    render() {
        const {
            totalPages,
            isLoading,
            mix,
            renderOnlyPagination
        } = this.props;

        if (renderOnlyPagination) {
            return this.renderPagination();
        }

        if (!isLoading && totalPages === 0) {
            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
            >
                { this.renderTitle() }
                { this.renderLoadButton() }
                { this.renderPages() }
                { this.renderPagination() }
            </div>
        );
    }
}

export default ProductListComponent;
