import {
    ImageZoomPopup as SourceImageZoomPopup
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.override.style';

/** @namespace Scandipwa/Component/ImageZoomPopup/Component */
export class ImageZoomPopupComponent extends SourceImageZoomPopup {
    render() {
        const {
            children
        } = this.props;

        return children;
    }
}

export default ImageZoomPopupComponent;
