import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ImageMagnifier from './ImageMagnifier.component';

/** @namespace Scandipwa/Component/ImageMagnifier/Container */
export class ImageMagnifierContainer extends PureComponent {
    static propTypes = {
        productId: PropTypes.number.isRequired,
        gallery: PropTypes.arrayOf().isRequired,
        activeImage: PropTypes.number,
        zoomLevel: PropTypes.number
    };

    static defaultProps = {
        activeImage: [],
        zoomLevel: 4
    };

    state = {
        isMouseOnImage: 0
    };

    componentDidMount() {
        this.addMouseEvents();
    }

    componentDidUpdate(prevProps) {
        const { activeImage } = this.props;

        if (activeImage !== prevProps.activeImage) {
            this.addMouseEvents();
        }
    }

    componentWillUnmount() {
        const { activeImage } = this.props;
        const zoomableImage = document.getElementsByClassName('ProductGallery-Slider')[activeImage];

        if (!zoomableImage) {
            return;
        }

        if (zoomableImage) {
            const new_zoomableImage = zoomableImage.cloneNode(true);
            zoomableImage.parentNode.replaceChild(new_zoomableImage, zoomableImage);
        }
    }

    containerProps() {
        const { zoomLevel, activeImage, gallery } = this.props;
        const { isMouseOnImage } = this.state;
        return {
            isActiveImageZoomable: this.checkIsActiveImageZoomable(), activeImage, isMouseOnImage, zoomLevel, gallery
        };
    }

    addMouseEvents() {
        const { activeImage } = this.props;

        const zoomableImage = document.getElementsByClassName('ProductGallery-SliderImage')[activeImage];

        if (!zoomableImage) {
            return;
        }

        zoomableImage.addEventListener('mouseenter', () => {
            this.setState({
                isMouseOnImage: true
            });
            this.getMouseCoordinates();
        });
        zoomableImage.addEventListener('touchstart', () => {
            this.setState({
                isMouseOnImage: true
            });
            this.getMouseCoordinates();
        });

        zoomableImage.addEventListener('mouseleave', () => {
            this.setState({
                isMouseOnImage: false
            });
        });
    }

    checkIsActiveImageZoomable() {
        const { gallery, activeImage, isImageZoomPopupActive } = this.props;

        return gallery[activeImage] && isImageZoomPopupActive;
    }

    getMouseCoordinates() {
        const { activeImage } = this.props;

        if (this.checkIsActiveImageZoomable()) {
            const image = document.getElementsByClassName('Slider-Wrapper')[0]
                .children[activeImage].children[0];

            if (!image) {
                return;
            }

            image.addEventListener('mousemove', (e) => {
                const elem = e.currentTarget;
                const { top, left } = elem.getBoundingClientRect();
                this.magnifyImage(e.pageX, e.pageY, top, left);
            });
        }
    }

    magnifyImage(x_pos, y_pos, top, left) {
        const { zoomLevel } = this.props;
        const component = document.getElementsByClassName('ImageMagnifier')[0];
        const productGallerySlide = document.getElementsByClassName('ProductGallery-SliderImage')[0];

        const imageContainer = component.getElementsByClassName('ImageMagnifier-ImageWrapper')[0]
            .getElementsByClassName('Image_ratio_square')[0];

        const image = imageContainer.getElementsByClassName('Image-Image')[0];

        const x = x_pos - left;
        const y = y_pos - top;

        if (!component || !productGallerySlide || !imageContainer || !image) {
            return;
        }

        // eslint-disable-next-line no-magic-numbers
        imageContainer.style.width = `${window.innerHeight * 0.38}px`;
        const slideWidth = productGallerySlide.offsetWidth;
        const slideHeight = productGallerySlide.offsetHeight;

        const containerWidth = parseInt(imageContainer.style.width, 10);
        const containerHeight = containerWidth;

        image.style.width = `${slideWidth * zoomLevel}px`;
        image.style.height = `${slideHeight * zoomLevel}px`;
        image.style.left = `${-x * zoomLevel + containerWidth / 2}px`;
        image.style.top = `${-y * zoomLevel + containerHeight / 2}px`;

        component.style.left = `${x_pos}px`;
        component.style.top = `${y_pos}px`;
    }

    render() {
        return (
            <ImageMagnifier
              { ...this.containerProps() }
            />
        );
    }
}

export default ImageMagnifierContainer;
