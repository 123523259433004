import { connect } from 'react-redux';

import Popup from 'Component/Popup';
import {
    ImageZoomPopupContainer as SourceImageZoomPopupContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.container';

import ImageZoomPopup from './ImageZoomPopup.component';

/** @namespace Scandipwa/Component/ImageZoomPopup/Container */
export class ImageZoomPopupContainer extends SourceImageZoomPopupContainer {
    render() {
        const {
            isActive,
            children,
            mix,
            popupId,
            isMobile,
            onClose
        } = this.props;

        if (!isActive || isMobile) {
            return children;
        }

        return (
            <Popup
              id={ popupId }
              clickOutside
              mix={ { block: 'ImageZoomPopup', mix } }
              contentMix={ { block: 'ImageZoomPopup', elem: 'PopupContent' } }
              onClose={ onClose }
              onHide={ onClose }
              disableChangeHeaderState
            >
                <ImageZoomPopup
                  { ...this.props }
                />
            </Popup>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageZoomPopupContainer);
