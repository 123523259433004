import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    onVisible() {
        const { onVisible, disableChangeHeaderState } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();

        if (!disableChangeHeaderState) {
            window.addEventListener('popstate', this.hidePopUp);

            window.history.pushState(
                {
                    popupOpen: true
                },
                '',
                location.pathname
            );
        }

        onVisible();
    }

    onHide() {
        const { onHide, disableChangeHeaderState } = this.props;

        if (!disableChangeHeaderState) {
            window.removeEventListener('popstate', this.hidePopUp);
        }

        this.unfreezeScroll();

        onHide();
    }

    hidePopUp = () => {
        const {
            hideActiveOverlay, goToPreviousNavigationState, onClose, disableChangeHeaderState
        } = this.props;
        const isVisible = this.getIsVisible();
        if (isVisible) {
            this.unfreezeScroll();
            hideActiveOverlay();

            if (!disableChangeHeaderState) {
                goToPreviousNavigationState();
            }

            onClose();
        }
    };
}

export default PopupComponent;
