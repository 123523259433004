import { withRouter } from 'react-router-dom';

import AdvoxSlider from 'Component/AdvoxSlider/AdvoxSlider.component';
import ArrowNext from 'Component/ArrowNext';
import ArrowPrev from 'Component/ArrowPrev';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import { ProductListPage as SourceProductListPage } from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.style.scss';

/** @namespace Scandipwa/Component/ProductListPage/Component */
export class ProductListPage extends SourceProductListPage {
    renderNextArrow(props) {
        const { forBlog } = this.props;
        const { className, style, onClick } = props;

        return (
            <div
              className={ className }
              style={ style }
              onClick={ onClick }
            >
                { forBlog ? <ArrowNext /> : <ChevronIcon direction={ RIGHT } /> }

            </div>
        );
    }

    renderPrevArrow(props) {
        const { forBlog } = this.props;
        const { className, style, onClick } = props;

        return (
            <div
              className={ className }
              style={ style }
              onClick={ onClick }
            >
                { forBlog ? <ArrowPrev /> : <ChevronIcon direction={ LEFT } /> }
            </div>
        );
    }

    render() {
        const {
            pageNumber,
            wrapperRef,
            mix,
            isWidget
        } = this.props;

        if (isWidget) {
            const NextArrow = this.renderNextArrow.bind(this);
            const PrevArrow = this.renderPrevArrow.bind(this);

            return (
                <ul
                  block="ProductListPage"
                  mods={ { isWidget: true } }
                  key={ pageNumber }
                  ref={ wrapperRef }
                >
                    <AdvoxSlider
                      mix={ { block: 'ProductListPage', elem: 'Slider' } }
                      variant="none"
                      settings={ {
                          dots: true,
                          arrows: true,
                          speed: 500,
                          slidesToShow: 4,
                          slidesToScroll: 1,
                          infinite: true,
                          draggable: true,
                          nextArrow: <NextArrow />,
                          prevArrow: <PrevArrow />,
                          responsive: [
                              {
                                  breakpoint: 1024,
                                  settings: {
                                      slidesToShow: 2,
                                      slidesToScroll: 1
                                  }
                              },
                              {
                                  breakpoint: 728,
                                  settings: {
                                      arrows: true,
                                      slidesToShow: 1,
                                      slidesToScroll: 1
                                  }
                              }
                          ]
                      } }
                    >
                        { this.renderItems() }
                    </AdvoxSlider>
                </ul>
            );
        }

        return (
            <ul
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              key={ pageNumber }
              ref={ wrapperRef }
            >
                { this.renderItems() }
            </ul>
        );
    }
}

export default withRouter(ProductListPage);
